import React from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import Dashboard from './components/Dashboard';

// Create an Apollo Client instance
const client = new ApolloClient({
  uri: 'https://gateway-arbitrum.network.thegraph.com/api/ac19b48679c95f6d5d496a14d677d9fc/subgraphs/id/Dtr9rETvwokot4BSXaD5tECanXfqfJKcvHuaaEgPDD2D',
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <div className="App">
        <main>
          <Dashboard />
        </main>
      </div>
    </ApolloProvider>
  );
}

export default App;