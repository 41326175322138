import React, { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Loader2, ArrowUpDown } from 'lucide-react';

const GET_DATA_POINTS = gql`
  query GetDataPoints($startEpoch: String!) {
    queryDataPoints(
      where: { start_epoch_gt: $startEpoch, query_count_gt: 1, gateway_id: "mainnet-arbitrum" }
      first: 1000
      orderBy: start_epoch
      orderDirection: asc
    ) {
      start_epoch
      avg_query_fee
      query_count
      total_query_fees
      max_query_fee
      subgraph_deployment_ipfs_hash
      chain_id
    }
  }
`;

interface DataPoint {
  start_epoch: string;
  avg_query_fee: string;
  query_count: string;
  total_query_fees: string;
  max_query_fee: string;
  subgraph_deployment_ipfs_hash: string;
  chain_id: string;
}

interface ProcessedDataPoint extends Omit<DataPoint, 'avg_query_fee' | 'query_count' | 'total_query_fees' | 'max_query_fee'> {
  avg_query_fee: number;
  query_count: number;
  total_query_fees: number;
  max_query_fee: number;
}

type SortKey = keyof Omit<ProcessedDataPoint, 'start_epoch'>;

const Dashboard: React.FC = () => {
  const [startEpoch, setStartEpoch] = useState<string>('');
  const [startEpochTime, setStartEpochTime] = useState<string>('');
  const [timeZone, setTimeZone] = useState<string>('');
  const [sortKey, setSortKey] = useState<SortKey>('query_count');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

  useEffect(() => {
    const currentEpoch = Math.floor(Date.now() / 1000);
    const twentyFourHoursAgo = currentEpoch - 24 * 60 * 60;
    const roundedStartEpoch = twentyFourHoursAgo - (twentyFourHoursAgo % 300);
    setStartEpoch(roundedStartEpoch.toString());
    
    // Format the time using toLocaleString
    const startTime = new Date(roundedStartEpoch * 1000).toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    });
    setStartEpochTime(startTime);

    // Get the time zone
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(timeZone);
  }, []);

  const { loading, error, data } = useQuery<{ queryDataPoints: DataPoint[] }>(GET_DATA_POINTS, {
    variables: { startEpoch },
    skip: !startEpoch,
  });

  const processData = (rawData: DataPoint[] | undefined): ProcessedDataPoint[] => {
    if (!rawData) return [];

    const groupedData = rawData.reduce((acc, point) => {
      if (!acc[point.subgraph_deployment_ipfs_hash] || 
          parseInt(acc[point.subgraph_deployment_ipfs_hash].start_epoch) < parseInt(point.start_epoch)) {
        acc[point.subgraph_deployment_ipfs_hash] = point;
      }
      return acc;
    }, {} as Record<string, DataPoint>);

    return Object.values(groupedData)
      .map(point => ({
        ...point,
        start_epoch: new Date(parseInt(point.start_epoch) * 1000).toUTCString(),
        avg_query_fee: parseFloat(point.avg_query_fee),
        query_count: parseInt(point.query_count),
        total_query_fees: parseFloat(point.total_query_fees),
        max_query_fee: parseFloat(point.max_query_fee),
      }));
  };

  const sortData = (data: ProcessedDataPoint[]): ProcessedDataPoint[] => {
    return [...data].sort((a, b) => {
      if (a[sortKey] < b[sortKey]) return sortDirection === 'asc' ? -1 : 1;
      if (a[sortKey] > b[sortKey]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const handleSort = (key: SortKey) => {
    if (key === sortKey) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortDirection('desc');
    }
  };

  const sortedData = sortData(processData(data?.queryDataPoints));

  if (loading) return (
    <div className="flex items-center justify-center h-screen bg-gray-900">
      <Loader2 className="mr-2 h-16 w-16 animate-spin text-gray-200" />
    </div>
  );

  if (error) return (
    <div className="flex items-center justify-center h-screen bg-gray-900">
      <Card className="bg-gray-800 text-gray-200">
        <CardHeader>
          <CardTitle className="text-red-500">Error</CardTitle>
        </CardHeader>
        <CardContent>
          <p>{error.message}</p>
        </CardContent>
      </Card>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-900 text-gray-200">
      <div className="container mx-auto p-4 space-y-6">
        <h1 className="text-3xl font-bold text-center my-8">GraphQL Query Dashboard</h1>
        <p className="text-center text-gray-400">Query Start Time: {startEpochTime} ({timeZone})</p>

        <Card className="bg-gray-800 text-gray-200">
          <CardHeader>
            <CardTitle>Data Table</CardTitle>
          </CardHeader>
          <CardContent>
            {sortedData.length > 0 ? (
              <div className="overflow-x-auto">
                <Table className="table-auto w-full text-gray-200">
                  <TableHeader>
                    <TableRow>
                      <TableHead className="bg-gray-700">Avg Query Fee <ArrowUpDown className="ml-2 h-4 w-4 cursor-pointer inline" onClick={() => handleSort('avg_query_fee')} /></TableHead>
                      <TableHead className="bg-gray-700">Query Count <ArrowUpDown className="ml-2 h-4 w-4 cursor-pointer inline" onClick={() => handleSort('query_count')} /></TableHead>
                      <TableHead className="bg-gray-700">Total Query Fees <ArrowUpDown className="ml-2 h-4 w-4 cursor-pointer inline" onClick={() => handleSort('total_query_fees')} /></TableHead>
                      <TableHead className="bg-gray-700">Max Query Fee <ArrowUpDown className="ml-2 h-4 w-4 cursor-pointer inline" onClick={() => handleSort('max_query_fee')} /></TableHead>
                      <TableHead className="bg-gray-700">Subgraph Deployment IPFS Hash <ArrowUpDown className="ml-2 h-4 w-4 cursor-pointer inline" onClick={() => handleSort('subgraph_deployment_ipfs_hash')} /></TableHead>
                      <TableHead className="bg-gray-700">Chain ID <ArrowUpDown className="ml-2 h-4 w-4 cursor-pointer inline" onClick={() => handleSort('chain_id')} /></TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {sortedData.map((point, index) => (
                      <TableRow key={index} className="bg-gray-800">
                        <TableCell>{point.avg_query_fee.toFixed(6)}</TableCell>
                        <TableCell>{point.query_count.toLocaleString()}</TableCell>
                        <TableCell>{point.total_query_fees.toFixed(6)}</TableCell>
                        <TableCell>{point.max_query_fee.toFixed(6)}</TableCell>
                        <TableCell className="whitespace-nowrap">{point.subgraph_deployment_ipfs_hash}</TableCell>
                        <TableCell>{point.chain_id}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            ) : (
              <p className="text-center text-gray-500">No data available for the table.</p>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Dashboard;